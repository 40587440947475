<template>
  <div id="step_ad_plan" class="step-ad-plan-container">
    <a-tabs v-if="+steps === 1" :activeKey="activeKey" :animated="false">
      <a-tab-pane :key="1" tab="创建账号" :force-render="true">
        <AdPlanForm ref="AdPlanForm" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import AdPlanForm from './AdPlanForm'
export default {
  components: { AdPlanForm },
  inject: ['isAdmin'],
  data () {
    return {
      // tab值
      activeKey: 1,
      // 当前步骤
      steps: 1,
      // 是否是添加
      isAdd: false
    }
  },
  watch: {
    $route: {
      handler (val) {
        // 获取当前步骤
        this.steps = val && val.query && +val.query.steps
        // 刷新页面处理
        // 非第一步，显示详情
        this.steps && this.steps !== 1 && (this.activeKey = 2)
        // 第一步，显示form表单
        this.steps && this.steps === 1 && (this.activeKey = 1)
        // 获取当前是否是添加状态
        this.isAdd = val.query.type === 'add'
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 调用子组件form表单的提交方法
    handleSubmitAdPlan () {
      return new Promise(async (resolve) => {
        const res = await this.$refs.AdPlanForm.handleSubmitAdPlan()
        // 抛出给父级组件
        resolve(res)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.step-ad-plan-container {
  .select_plan {
    display: flex;
    height: 40px;
    line-height: 36px;
    margin: 30px 0;
  }
}
</style>
